import axios from '@/axios.js'

export default {
    getResources: async (parameters) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/resources/`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getResource: async (resourceUuid) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/resources/${resourceUuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    createResources: async (resourceUuid, body) => {
        try {
            const response = await axios.editorapi.post(`/v4/app-configurator/resources/${resourceUuid}`, body)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    updateResources: async (resourceUuid, body) => {
        try {
            const response = await axios.editorapi.patch(`/v4/app-configurator/resources/${resourceUuid}`, body)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    updateDynamicResources: async (resourceUuid, body) => {
        const response = await axios.editorapi.patch(`/v4/app-configurator/resources/dynamics/${resourceUuid}`, body)
        return response.data
    },
    getCustomerResources: async (versionUuid) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/customer-resources/${versionUuid}`)
            return response.data.object
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    },
    uploadCustomerResources: async (versionUuid, body) => {
        try {
            const response = await axios.editorapi.post(`/v4/app-configurator/customer-resources/${versionUuid}`, body)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    deleteCustomerResources: async (versionUuid, imageName) => {
        try {
            const response = await axios.editorapi.delete(`/v4/app-configurator/customer-resources/${versionUuid}/${imageName}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
}
